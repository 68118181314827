<style scoped>
  .dotbottom {
    border-bottom: 2px dotted black;
  }
</style>
<template>
  <div v-if="item">
    <b-card class="mb-2">
      <b-row>
        <b-col cols="12" class="text-center">
          <strong>
            <router-link :to="'/admin/company/' + item.id" class="link u">
              {{ item.company }}
            </router-link>
          </strong>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" class="text-center">
          <span v-b-popover.hover.focus="popoverData" tabindex="0" class="dotbottom">{{ item.contact }}</span>
        </b-col>
        <b-col cols="12" class="text-center" v-if="item.bookkeeper_id">
          <span class="text--sbs-red">{{ item.bookkeeper }}</span>
        </b-col>
      </b-row>
      <b-row no-gutters align-v="center" class="pt-3">
        <b-col cols="3" @click="changeStage(-1)" class="cursor-pointer" v-if="!hideBack">
          <b-icon icon="caret-left-fill" scale="1.5" class="moveicon d-none d-lg-block"></b-icon>
          <b-icon icon="caret-up-fill" scale="1.5" class="moveicon d-block d-lg-none"></b-icon>
        </b-col>
        <b-col cols="6" :offset="hideBack ? 3 : 0">
          <b-dropdown text="Actions" block size="sm">
            <b-dropdown-item
              v-if="item.financialDoc && item.financialDoc != ''"
              :href="item.financialDoc"
            >
              <b-icon icon="file-earmark-text"></b-icon> Financial Form
            </b-dropdown-item>
            <b-dropdown-item @click="shownotes()">
              View Notes
            </b-dropdown-item>
            <!-- <b-dropdown-item @click="resendEmail()" variant="info"> -->
            <b-dropdown-item @click="resendEmailModal = true" variant="info">
              <b-icon icon="arrow-clockwise"></b-icon> Resend Email
            </b-dropdown-item>
            <b-dropdown-item @click="remove()" variant="danger">
              <b-icon icon="x-circle"></b-icon> Remove
            </b-dropdown-item>
          </b-dropdown>
        </b-col>
        <b-col cols="3" @click="changeStage(1)" class="cursor-pointer" v-if="!hideForward">
          <b-icon icon="caret-right-fill" scale="1.5" class="moveicon d-none d-lg-block float-right"></b-icon>
          <b-icon icon="caret-down-fill" scale="1.5" class="moveicon d-block d-lg-none float-right"></b-icon>
        </b-col>
        <b-col v-else cols="12" class="mt-2 mb-n3">
          <AddEditCompany @refresh="completeItem()" :compId="+item.id" edit>
            <template>
              <b-button variant="success">Set Complete</b-button>
            </template>
          </AddEditCompany>
        </b-col>
      </b-row>
    </b-card>

    <b-modal
      v-model="showmodal"
      :ok-disabled="loading || (statusId == 4 && bookkeeperId == null)"
      @ok="saveStatusChange"
      @hidden="reset()"
      no-close-on-backdrop
      ok-variant="success"
      ok-title="Save"
      no-close-on-esc
      no-stacking
      hide-header
    >
    <!-- ADD ASSIGN BOOKKEEPER WHEN GOING TO THE STEP FOR WORKING ON FINANCIAL FILE -->
      Are you sure you want to change <b>{{ item.company }}</b>'s status to {{ statusId | statusname(statusOptions) }}?
      <b-form-group :label="(statusId == 4 ? '*' : '') + 'Bookkeeper'" class="mt-3">
        <b-form-select
          v-model="bookkeeperId"
          :options="bookkeepers"
          text-field="fullname"
          value-field="id"
        >
          <template #first>
            <b-select-option :value="null" disabled>Select One{{ statusId == 4 ? ' - REQUIRED' : ''}}</b-select-option>
          </template>
        </b-form-select>
      </b-form-group>

      <div v-if="statusId == 4">
        <b-form-group label="From">
          <b-form-row>
            <b-col cols="6">
              <b-form-select v-model="selectedFromMonth" :options="filteredMonths(selectedFromYear)">
              </b-form-select>
            </b-col>
            <b-col cols="6">
              <b-form-select v-model="selectedFromYear" :options="years">
              </b-form-select>
            </b-col>
          </b-form-row>
        </b-form-group>
        <b-form-group label="To">
          <b-form-row>
            <b-col cols="6">
              <b-form-select v-model="selectedToMonth" :options="filteredMonths(selectedToYear)">
              </b-form-select>
            </b-col>
            <b-col cols="6">
              <b-form-select v-model="selectedToYear" :options="years">
              </b-form-select>
            </b-col>
          </b-form-row>
        </b-form-group>
        <div v-if="ticketCount > 0" class="text-danger">
          Note: This will create {{ ticketCount }} tickets, due 45 days out from today.<br>
          ***The 1st of next month will create this month's ticket.***
        </div>
      </div>
      <b-form-group label="Status Change Notes (optional)" :description="(note ? note.length : 0) + '/200'">
        <b-textarea v-model="note" maxlength="200"></b-textarea>
      </b-form-group>
    </b-modal>

    <b-modal
      v-model="notesmodal"
      hide-footer
      scrollable
      size="lg"
    >
      <div
        v-for="n in notelist"
        :key="n.id"
      >
        <fieldset class="note">
          <legend>{{ n.author }}</legend>
          <small>{{ n.updated }}</small><br>
          <p v-if="n.previousstatus || n.newstatus">
            Changed status from {{ n.previousstatus ? n.previousstatus : 'null' }} to {{ n.newstatus }}
          </p>
          <p v-if="n.note && n.note != ''">
            {{ n.note }}
          </p>
          <p v-if="n.changelog">
            <b-row>
              <b-col cols="6">
                <strong>Previous Data</strong><br>
                <pre>{{ n.changelog.previousdata }}</pre>
              </b-col>
              <b-col cols="6">
                <strong>New Data</strong><br>
                <pre>{{ n.changelog.newdata }}</pre>
              </b-col>
            </b-row>
          </p>
        </fieldset>
      </div>
      <div
        v-if="notelist.length == 0 && !loadingnotes"
        class="text-center"
      >
        No notes to be displayed
      </div>
    </b-modal>

    <b-modal
      v-model="resendEmailModal"
      @shown="emailModalInput = item.email"
      @ok="resendEmail()"
    >
      This action will re-send an email based on their current step. Is this okay?<br><br>

      <b-form-group label="Email to send to">
        <b-form-input v-model="emailModalInput"></b-form-input>
        <b-form-text>If left empty it will default to sending to the primary contact of this company</b-form-text>
      </b-form-group>
    </b-modal>
  </div>
</template>
<script>
import OnboardingService from '@/services/OnboardingService'
import AdminService from '@/services/AdminService'

import AddEditCompany from '@/components/admin/CompanyModal'
import CompanyService from '@/services/CompanyService'

export default {
  props: {
    item: {
      type: Object,
      defualt: null
    },

    hideBack: {
      type: Boolean,
      default: false
    },

    hideForward: {
      type: Boolean,
      default: false
    },

    statusOptions: {
      type: Array,
      default: () => { return [] }
    }
  },

  components: {
    AddEditCompany
  },

  data () {
    const currentYear = new Date().getFullYear()
    const currentMonth = new Date().getMonth() + 1
    return {
      statusId: null,
      note: null,
      notify: false,
      loading: false,
      showmodal: false,
      bookkeepers: [],
      bookkeeperId: null,
      notesmodal: false,
      notelist: [],
      loadingnotes: false,
      resendEmailModal: false,
      cleanup_from: null,
      cleanup_to: null,
      emailModalInput: '',
      ticketCount: 0,
      months: Array.from({ length: 12 }, (v, k) => ({
        value: k + 1,
        text: new Date(0, k).toLocaleString('default', { month: 'short' })
      })),
      years: [currentYear - 4, currentYear - 3, currentYear - 2, currentYear - 1, currentYear],
      selectedFromMonth: 1,
      selectedFromYear: currentYear,
      selectedToMonth: currentMonth - 1, // Default is last month
      selectedToYear: currentYear
    }
  },

  mounted () {
    this.statusId = this.item.companystatus_id
    if (this.item.cleanup_from !== null) {
      this.cleanup_from = this.item.cleanup_from + 'T00:00:00'
      const fromDate = new Date(this.cleanup_from)
      console.log('to date: ' + fromDate + 'Month: ' + fromDate.getMonth() + 'Year: ' + fromDate.getFullYear())
      this.selectedFromMonth = fromDate.getMonth() + 1
      this.selectedFromYear = fromDate.getFullYear()
    }
    if (this.item.cleanup_to !== null) {
      this.cleanup_to = this.item.cleanup_to + 'T00:00:00'
      const toDate = new Date(this.cleanup_to)
      console.log('to date: ' + toDate + 'Month: ' + toDate.getMonth() + 'Year: ' + toDate.getFullYear())
      this.selectedToMonth = toDate.getMonth() + 1
      this.selectedToYear = toDate.getFullYear()
    }
  },

  watch: {
    selectedFromYear (newVal) {
      this.validateDates()
    },
    selectedFromMonth (newVal) {
      this.validateDates()
    },
    selectedToYear (newVal) {
      this.validateDates()
    },
    selectedToMonth (newVal) {
      this.validateDates()
    }
  },

  methods: {
    filteredMonths (year) {
      if (year < new Date().getFullYear()) {
        return this.months
      }
      return this.months.slice(0, new Date().getMonth()) // Removes current month too
    },
    calculateTickets () {
      let from = new Date(this.selectedFromYear, this.selectedFromMonth - 1, 1)
      const to = new Date(this.selectedToYear, this.selectedToMonth - 1, 1)
      this.ticketCount = 0
      while (from <= to) {
        this.ticketCount++
        from = new Date(from.setMonth(from.getMonth() + 1))
      }
    },
    validateDates () {
      const from = new Date(this.selectedFromYear, this.selectedFromMonth - 1, 1)
      const to = new Date(this.selectedToYear, this.selectedToMonth - 1, 1)
      if (from > to) {
        this.selectedFromMonth = this.selectedToMonth
        this.selectedFromYear = this.selectedToYear
      }
      this.calculateTickets()
    },

    openStatuChange () {
      this.loading = true
      AdminService.getBookkeepers().then(
        (response) => {
          this.bookkeepers = response.data.info
        }
      ).finally(() => {
        if (this.statusId === 4) {
          this.calculateTickets()
        }
        this.bookkeeperId = this.item.bookkeeper_id
        this.loading = false
        this.showmodal = true
      })
    },
    validateAndPrepareData () {
      if (this.statusId === 4) {
        if (!this.selectedFromYear || !this.selectedFromMonth || !this.selectedToYear || !this.selectedToMonth) {
          alert('All date selections are required!')
          return false
        }
        this.cleanup_from = `${this.selectedFromYear}-${this.selectedFromMonth}-01`
        this.cleanup_to = `${this.selectedToYear}-${this.selectedToMonth}-01`
        this.calculateTickets()
        console.log('from: ' + this.cleanup_from + 'to: ' + this.cleanup_to + 'ticket: ' + this.ticketCount)
        return true
      }
      return true
    },
    saveStatusChange (bvModalEvt) {
      bvModalEvt.preventDefault()
      if (!this.validateAndPrepareData()) return
      this.loading = true
      const changedata = {
        companystatus_id: this.statusId,
        cleanup_from: this.cleanup_from,
        cleanup_to: this.cleanup_to,
        ticket_count: this.ticketCount
      }
      if (this.bookkeeperId && this.bookkeeperId != null) {
        changedata.bookkeeper_id = this.bookkeeperId
      }
      if (this.note && this.note != null && this.note !== '') {
        changedata.note = this.note
      }
      if (this.statusId === 4) {
        if (changedata.note && changedata.note != null && changedata.note !== '') {
          changedata.note += '\nFrom Date: ' + this.cleanup_from + '\nTo Date: ' + this.cleanup_to + '\nNo of tickets: ' + this.ticketCount
        } else {
          changedata.note = 'From Date: ' + this.cleanup_from + '\nTo Date: ' + this.cleanup_to + '\nNo of tickets: ' + this.ticketCount
        }
      }
      OnboardingService.changeCompanyStatus(this.item.id, changedata).then(
        (response) => {
          this.showmodal = false
          this.$aimNotify.notify(response)
          this.$emit('refresh')
        },
        (err) => {
          this.$aimNotify.error(err.response)
        }
      ).finally(() => { this.loading = false })
    },

    changeStage (to) {
      let currIdx = -1

      for (let i = 0; i < this.statusOptions.length; i++) {
        if (this.statusOptions[i].id === this.item.companystatus_id) {
          currIdx = i
        }
      }
      this.statusId = this.statusOptions[currIdx + to].id
      this.openStatuChange()
    },

    completeItem () {
      this.loading = true

      const changedata = {
        companystatus_id: 10
      }

      OnboardingService.changeCompanyStatus(this.item.id, changedata).then(
        (response) => {
          this.showmodal = false
          this.$aimNotify.notify(response)
          this.$emit('refresh')
        },
        (err) => {
          this.$aimNotify.error(err.response)
        }
      ).finally(() => { this.loading = false })
    },

    shownotes () {
      this.loadingnotes = true
      this.notelist = []
      this.notesmodal = true

      OnboardingService.getNotes(this.item.id).then(
        (response) => {
          this.notelist = response.data.info
        },
        (err) => {
          this.$aimNotify.error(err.response)
        }
      ).finally(() => { this.loadingnotes = false })
    },

    resendEmail () {
      OnboardingService.resendEmail(this.item.id, this.emailModalInput).then(
        (response) => {
          this.$aimNotify.notify(response)
        },
        (err) => {
          this.$aimNotify.error(err.response)
        }
      ).finally(() => { this.emailModalInput = '' })
    },

    remove () {
      this.$bvModal.msgBoxConfirm('Are you sure you wish to remove this company?').then(
        val => {
          if (val) {
            this.loading = true
            CompanyService.deleteCompany(this.item.id).then(
              (response) => {
                this.$emit('refresh')
                this.$aimNotify.notify(response)
              },
              (err) => {
                this.$aimNotify.error(err.response)
              }
            ).finally(() => {
              this.loading = false
            })
          }
        }
      )
    },

    reset () {
      this.note = null
      this.statusId = this.item.companystatus_id
      this.bookkeeperId = null
      this.notify = false
    },

    phoneFormatter (val) {
      const cleaned = ('' + val).replace(/\D/g, '')
      const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)

      if (match) {
        return '(' + match[1] + ')' + match[2] + '-' + match[3]
      }
      return null
    }
  },

  filters: {
    statusname (val, statuses) {
      let statusname = ''
      statuses.some(el => {
        if (el.id === val) {
          statusname = el.name
          return true
        }
        return false
      })

      return statusname
    }
  },

  computed: {
    popoverData () {
      return {
        html: true,
        title: () => {
          return this.item.contact
        },
        content: () => {
          const phoneFormat = this.phoneFormatter(this.item.phonenumber)
          let res = '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope" viewBox="0 0 16 16"><path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z"/></svg>'
          res += ' <a class="link" href="mailto:' + this.item.email + '">' + this.item.email + '</a><br>'
          if (this.item.phonenumber && phoneFormat) {
            res += '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone" viewBox="0 0 16 16"><path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/></svg>'
            res += ' <a class="link" href="tel:' + this.item.phonenumber + '">' + phoneFormat + '</a>'
          }

          return res
        }
      }
    },

    hasActions () {
      const hasFin = this.item.financialDoc && this.item.financialDoc !== ''
      return hasFin
    }
  }
}
</script>
