var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.item
    ? _c(
        "div",
        [
          _c(
            "b-card",
            { staticClass: "mb-2" },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { staticClass: "text-center", attrs: { cols: "12" } },
                    [
                      _c(
                        "strong",
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "link u",
                              attrs: { to: "/admin/company/" + _vm.item.id }
                            },
                            [_vm._v(" " + _vm._s(_vm.item.company) + " ")]
                          )
                        ],
                        1
                      )
                    ]
                  )
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { staticClass: "text-center", attrs: { cols: "12" } },
                    [
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "b-popover",
                              rawName: "v-b-popover.hover.focus",
                              value: _vm.popoverData,
                              expression: "popoverData",
                              modifiers: { hover: true, focus: true }
                            }
                          ],
                          staticClass: "dotbottom",
                          attrs: { tabindex: "0" }
                        },
                        [_vm._v(_vm._s(_vm.item.contact))]
                      )
                    ]
                  ),
                  _vm.item.bookkeeper_id
                    ? _c(
                        "b-col",
                        { staticClass: "text-center", attrs: { cols: "12" } },
                        [
                          _c("span", { staticClass: "text--sbs-red" }, [
                            _vm._v(_vm._s(_vm.item.bookkeeper))
                          ])
                        ]
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "b-row",
                {
                  staticClass: "pt-3",
                  attrs: { "no-gutters": "", "align-v": "center" }
                },
                [
                  !_vm.hideBack
                    ? _c(
                        "b-col",
                        {
                          staticClass: "cursor-pointer",
                          attrs: { cols: "3" },
                          on: {
                            click: function($event) {
                              return _vm.changeStage(-1)
                            }
                          }
                        },
                        [
                          _c("b-icon", {
                            staticClass: "moveicon d-none d-lg-block",
                            attrs: { icon: "caret-left-fill", scale: "1.5" }
                          }),
                          _c("b-icon", {
                            staticClass: "moveicon d-block d-lg-none",
                            attrs: { icon: "caret-up-fill", scale: "1.5" }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "b-col",
                    { attrs: { cols: "6", offset: _vm.hideBack ? 3 : 0 } },
                    [
                      _c(
                        "b-dropdown",
                        { attrs: { text: "Actions", block: "", size: "sm" } },
                        [
                          _vm.item.financialDoc && _vm.item.financialDoc != ""
                            ? _c(
                                "b-dropdown-item",
                                { attrs: { href: _vm.item.financialDoc } },
                                [
                                  _c("b-icon", {
                                    attrs: { icon: "file-earmark-text" }
                                  }),
                                  _vm._v(" Financial Form ")
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "b-dropdown-item",
                            {
                              on: {
                                click: function($event) {
                                  return _vm.shownotes()
                                }
                              }
                            },
                            [_vm._v(" View Notes ")]
                          ),
                          _c(
                            "b-dropdown-item",
                            {
                              attrs: { variant: "info" },
                              on: {
                                click: function($event) {
                                  _vm.resendEmailModal = true
                                }
                              }
                            },
                            [
                              _c("b-icon", {
                                attrs: { icon: "arrow-clockwise" }
                              }),
                              _vm._v(" Resend Email ")
                            ],
                            1
                          ),
                          _c(
                            "b-dropdown-item",
                            {
                              attrs: { variant: "danger" },
                              on: {
                                click: function($event) {
                                  return _vm.remove()
                                }
                              }
                            },
                            [
                              _c("b-icon", { attrs: { icon: "x-circle" } }),
                              _vm._v(" Remove ")
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  !_vm.hideForward
                    ? _c(
                        "b-col",
                        {
                          staticClass: "cursor-pointer",
                          attrs: { cols: "3" },
                          on: {
                            click: function($event) {
                              return _vm.changeStage(1)
                            }
                          }
                        },
                        [
                          _c("b-icon", {
                            staticClass:
                              "moveicon d-none d-lg-block float-right",
                            attrs: { icon: "caret-right-fill", scale: "1.5" }
                          }),
                          _c("b-icon", {
                            staticClass:
                              "moveicon d-block d-lg-none float-right",
                            attrs: { icon: "caret-down-fill", scale: "1.5" }
                          })
                        ],
                        1
                      )
                    : _c(
                        "b-col",
                        { staticClass: "mt-2 mb-n3", attrs: { cols: "12" } },
                        [
                          _c(
                            "AddEditCompany",
                            {
                              attrs: { compId: +_vm.item.id, edit: "" },
                              on: {
                                refresh: function($event) {
                                  return _vm.completeItem()
                                }
                              }
                            },
                            [
                              [
                                _c(
                                  "b-button",
                                  { attrs: { variant: "success" } },
                                  [_vm._v("Set Complete")]
                                )
                              ]
                            ],
                            2
                          )
                        ],
                        1
                      )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-modal",
            {
              attrs: {
                "ok-disabled":
                  _vm.loading ||
                  (_vm.statusId == 4 && _vm.bookkeeperId == null),
                "no-close-on-backdrop": "",
                "ok-variant": "success",
                "ok-title": "Save",
                "no-close-on-esc": "",
                "no-stacking": "",
                "hide-header": ""
              },
              on: {
                ok: _vm.saveStatusChange,
                hidden: function($event) {
                  return _vm.reset()
                }
              },
              model: {
                value: _vm.showmodal,
                callback: function($$v) {
                  _vm.showmodal = $$v
                },
                expression: "showmodal"
              }
            },
            [
              _vm._v(" Are you sure you want to change "),
              _c("b", [_vm._v(_vm._s(_vm.item.company))]),
              _vm._v(
                "'s status to " +
                  _vm._s(
                    _vm._f("statusname")(_vm.statusId, _vm.statusOptions)
                  ) +
                  "? "
              ),
              _c(
                "b-form-group",
                {
                  staticClass: "mt-3",
                  attrs: {
                    label: (_vm.statusId == 4 ? "*" : "") + "Bookkeeper"
                  }
                },
                [
                  _c("b-form-select", {
                    attrs: {
                      options: _vm.bookkeepers,
                      "text-field": "fullname",
                      "value-field": "id"
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "first",
                          fn: function() {
                            return [
                              _c(
                                "b-select-option",
                                { attrs: { value: null, disabled: "" } },
                                [
                                  _vm._v(
                                    "Select One" +
                                      _vm._s(
                                        _vm.statusId == 4 ? " - REQUIRED" : ""
                                      )
                                  )
                                ]
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      1183960421
                    ),
                    model: {
                      value: _vm.bookkeeperId,
                      callback: function($$v) {
                        _vm.bookkeeperId = $$v
                      },
                      expression: "bookkeeperId"
                    }
                  })
                ],
                1
              ),
              _vm.statusId == 4
                ? _c(
                    "div",
                    [
                      _c(
                        "b-form-group",
                        { attrs: { label: "From" } },
                        [
                          _c(
                            "b-form-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { cols: "6" } },
                                [
                                  _c("b-form-select", {
                                    attrs: {
                                      options: _vm.filteredMonths(
                                        _vm.selectedFromYear
                                      )
                                    },
                                    model: {
                                      value: _vm.selectedFromMonth,
                                      callback: function($$v) {
                                        _vm.selectedFromMonth = $$v
                                      },
                                      expression: "selectedFromMonth"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { cols: "6" } },
                                [
                                  _c("b-form-select", {
                                    attrs: { options: _vm.years },
                                    model: {
                                      value: _vm.selectedFromYear,
                                      callback: function($$v) {
                                        _vm.selectedFromYear = $$v
                                      },
                                      expression: "selectedFromYear"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        { attrs: { label: "To" } },
                        [
                          _c(
                            "b-form-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { cols: "6" } },
                                [
                                  _c("b-form-select", {
                                    attrs: {
                                      options: _vm.filteredMonths(
                                        _vm.selectedToYear
                                      )
                                    },
                                    model: {
                                      value: _vm.selectedToMonth,
                                      callback: function($$v) {
                                        _vm.selectedToMonth = $$v
                                      },
                                      expression: "selectedToMonth"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { cols: "6" } },
                                [
                                  _c("b-form-select", {
                                    attrs: { options: _vm.years },
                                    model: {
                                      value: _vm.selectedToYear,
                                      callback: function($$v) {
                                        _vm.selectedToYear = $$v
                                      },
                                      expression: "selectedToYear"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm.ticketCount > 0
                        ? _c("div", { staticClass: "text-danger" }, [
                            _vm._v(
                              " Note: This will create " +
                                _vm._s(_vm.ticketCount) +
                                " tickets, due 45 days out from today."
                            ),
                            _c("br"),
                            _vm._v(
                              " ***The 1st of next month will create this month's ticket.*** "
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: "Status Change Notes (optional)",
                    description: (_vm.note ? _vm.note.length : 0) + "/200"
                  }
                },
                [
                  _c("b-textarea", {
                    attrs: { maxlength: "200" },
                    model: {
                      value: _vm.note,
                      callback: function($$v) {
                        _vm.note = $$v
                      },
                      expression: "note"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-modal",
            {
              attrs: { "hide-footer": "", scrollable: "", size: "lg" },
              model: {
                value: _vm.notesmodal,
                callback: function($$v) {
                  _vm.notesmodal = $$v
                },
                expression: "notesmodal"
              }
            },
            [
              _vm._l(_vm.notelist, function(n) {
                return _c("div", { key: n.id }, [
                  _c("fieldset", { staticClass: "note" }, [
                    _c("legend", [_vm._v(_vm._s(n.author))]),
                    _c("small", [_vm._v(_vm._s(n.updated))]),
                    _c("br"),
                    n.previousstatus || n.newstatus
                      ? _c("p", [
                          _vm._v(
                            " Changed status from " +
                              _vm._s(
                                n.previousstatus ? n.previousstatus : "null"
                              ) +
                              " to " +
                              _vm._s(n.newstatus) +
                              " "
                          )
                        ])
                      : _vm._e(),
                    n.note && n.note != ""
                      ? _c("p", [_vm._v(" " + _vm._s(n.note) + " ")])
                      : _vm._e(),
                    n.changelog
                      ? _c(
                          "p",
                          [
                            _c(
                              "b-row",
                              [
                                _c("b-col", { attrs: { cols: "6" } }, [
                                  _c("strong", [_vm._v("Previous Data")]),
                                  _c("br"),
                                  _c("pre", [
                                    _vm._v(_vm._s(n.changelog.previousdata))
                                  ])
                                ]),
                                _c("b-col", { attrs: { cols: "6" } }, [
                                  _c("strong", [_vm._v("New Data")]),
                                  _c("br"),
                                  _c("pre", [
                                    _vm._v(_vm._s(n.changelog.newdata))
                                  ])
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ])
                ])
              }),
              _vm.notelist.length == 0 && !_vm.loadingnotes
                ? _c("div", { staticClass: "text-center" }, [
                    _vm._v(" No notes to be displayed ")
                  ])
                : _vm._e()
            ],
            2
          ),
          _c(
            "b-modal",
            {
              on: {
                shown: function($event) {
                  _vm.emailModalInput = _vm.item.email
                },
                ok: function($event) {
                  return _vm.resendEmail()
                }
              },
              model: {
                value: _vm.resendEmailModal,
                callback: function($$v) {
                  _vm.resendEmailModal = $$v
                },
                expression: "resendEmailModal"
              }
            },
            [
              _vm._v(
                " This action will re-send an email based on their current step. Is this okay?"
              ),
              _c("br"),
              _c("br"),
              _c(
                "b-form-group",
                { attrs: { label: "Email to send to" } },
                [
                  _c("b-form-input", {
                    model: {
                      value: _vm.emailModalInput,
                      callback: function($$v) {
                        _vm.emailModalInput = $$v
                      },
                      expression: "emailModalInput"
                    }
                  }),
                  _c("b-form-text", [
                    _vm._v(
                      "If left empty it will default to sending to the primary contact of this company"
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }