<template>
  <b-container fluid>
    <b-card class="text--black card-border" style="overflow: auto;">
      <!-- DESKTOP HEADERS -->
      <b-row align-h="center" class="desktop-fit">
        <b-col cols="12" lg="" class="queue header d-none d-lg-flex" v-for="stat in statusHeaders" :key="stat.id">
          <div class="h2 header">{{ stat.name }}({{ stat.length }})</div><br>
        </b-col>
      </b-row>
      <!-- items -->
      <b-row class="desktop-fit">
        <b-col cols="12" v-if="!noItems && !loading">
          <b-row align-h="center">
            <b-col cols="12" lg="" class="queue items"  v-for="(stat, key) in statuses" :key="stat.id">
              <div class="h2 header d-block d-lg-none">{{ stat.name }}</div>
              <Onboarding
                v-for="item in stat.items" :key="item.id"
                :item="item"
                :hideBack="key == 0"
                :hideForward="key == (statuses.length - 1)"
                @refresh="getStatusData()"
                :statusOptions="statusOptions"
              ></Onboarding>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="12">
          <div class="text-center h2 text-black-50 my-4" v-if="!loading && !firstload && noItems">
            No companies being onboarded
          </div>
          <div class="text-center h2 my-4" v-if="loading">
            <b-spinner></b-spinner>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </b-container>
</template>
<script>
import Onboarding from '@/components/admin/onboarding/Item'
import OnboardingService from '@/services/OnboardingService'

export default {
  data () {
    return {
      firstload: true,
      isAdmin: false,
      statuses: [],
      statusOptions: [],
      loading: false,
      statusHeaders: []
    }
  },

  components: {
    Onboarding
  },

  created () {
    this.init()
  },

  methods: {
    init () {
      this.loading = true
      this.statuses = []
      this.statusOptions = []
      this.statusHeaders = []
      OnboardingService.init().then(
        (response) => {
          this.statusOptions = response.data.info.options
          this.statuses = response.data.info.statuses

          this.statuses.forEach(el => {
            this.statusHeaders.push({ id: el.id, name: el.name, length: el.items.length })
          })
          this.firstload = false
        }
      ).finally(() => { this.loading = false })
    },

    getStatusData () {
      this.loading = true
      this.statuses = []
      this.statusOptions = []
      OnboardingService.init().then(
        (response) => {
          this.statusOptions = response.data.info.options
          this.statuses = response.data.info.statuses
          this.firstload = false
        }
      ).finally(() => { this.loading = false })
    }
  },

  computed: {
    noItems () {
      let notempty = false
      this.statuses.forEach(el => {
        if (el.items && el.items.length > 0) {
          notempty = true
        }
      })

      return !notempty
    }
  }
}
</script>
