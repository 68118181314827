import axios from 'axios'

const OnboardingService = {

  async init () {
    return axios({
      url: axios.defaults.baseURL + 'onboarding/init',
      method: 'GET'
    })
  },

  async changeCompanyStatus (companyId, data) {
    return axios({
      url: axios.defaults.baseURL + 'onboarding/update/' + companyId,
      method: 'POST',
      data: JSON.stringify(data)
    })
  },

  async getNotes (companyId) {
    return axios({
      url: axios.defaults.baseURL + 'onboarding/getnotes/' + companyId,
      method: 'GET'
    })
  },

  async resendEmail (companyId, email) {
    const data = {}
    if (email && email.length > 0 && email !== '') {
      data.email = email
    }
    return axios({
      url: axios.defaults.baseURL + 'onboarding/resendemail/' + companyId,
      method: 'POST',
      data: JSON.stringify(data)
    })
  },

  async welcome (companyId) {
    return axios({
      url: axios.defaults.baseURL + 'onboarding/welcome/' + companyId,
      method: 'GET'
    })
  }

}

export default OnboardingService
